<template>
  <div class="home">
    <nav-bar :value="buttonType" @change="btnChange" />
    <div v-show="buttonType === ''" class="text">
      <span style="user-select: none;">欢迎来到 梓齐の网站</span>
      <div class="footer">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14050002000334">
          <img src="https://www.yinqi729.cn/serve/files/beian.png" />
          <span>晋公网安备 14050002000334号</span>
        </a>
        <a href="https://beian.miit.gov.cn/" target="_blank" style="padding-left: 20px">晋ICP备2022003720号</a>
      </div>
    </div>
    <query v-show="buttonType === 'query'" />
    <star v-show="buttonType === 'star'" />
  </div>
</template>

<script>
import { unmountCanvas } from "./utils/snow"
import navBar from "./components/navbar.vue";
import query from "./components/query.vue";
import star from "./components/star.vue";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  components: { navBar, query, star },
  data() {
    return {
      buttonType: ''
    }
  },
  mixins: [ResizeMixin],
  beforeUnmount() {
    unmountCanvas()
  },
  created() {},
  methods: {
    btnChange(item) {
      const { type, src } = item
      if (this.buttonType === type) {
        this.buttonType = ''
      } else {
        this.buttonType = type
      }
      if (type === 'outLine') {
        this.buttonType = ''
        window.open(src)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/bg.jpg);
  background-size: 100% 100%;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  font-size: 50px;
  font-weight: 600;
}
.footer{
  height: 40px;
  line-height: 40px;
  width: 100%;
  position: absolute;
  bottom:0;
  left: 0;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #fff;
  }
  img {
    width: 14px;
    height: 14px;
  }
}

@media screen and (max-width: 700px) {
  .text {
    font-size: 30px;
  }
  .footer{
    font-size: 8px;
  }
}
</style>
