import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home/index.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  }
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

export default router;
